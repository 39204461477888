:root {
  --height: clamp(20.375rem, 10.895rem + 27.379vw, 43.75rem);
}

.backsplash {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  width: 99.3vw;
  /* aspect-ratio: 1.92 / 1; */
  height: var(--height);
  /* object-fit: cover; */
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 99.3vw;
  z-index: -1;
  /* aspect-ratio: 1.92 / 1; */

  height: var(--height);
  background: linear-gradient(
    180deg,
    rgba(12, 77, 107, 1) 0%,
    rgba(12, 77, 107, 0) 50%,
    rgba(1, 60, 88, 1) 100%
  );
  opacity: 0.7;
}

.headline {
  position: relative;
  --font-size: clamp(2.25rem, 1.7rem + 2.75vw, 5rem);
  padding: 0 clamp(0.625rem, -1.75rem + 11.875vw, 12.5rem);
  font-size: var(--font-size);
  line-height: calc(var(--font-size) * 1.6);
  text-align: center;
  padding-top: clamp(4.375rem, 4rem + 1.875vw, 6.25rem);
  color: var(--main-text-color);
}

.main-sub {
  --font-size: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
  text-align: center;
  margin-top: clamp(2.813rem, 2.75rem + 0.313vw, 3.125rem);
  padding: 0 clamp(0.625rem, -3.625rem + 21.25vw, 21.875rem);
  font-size: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
  line-height: calc(var(--font-size) * 1.6);
  color: var(--sub-text-color);
}

.action {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: clamp(0.938rem, -0.573rem + 5.686vw, 6.25rem);
}

.action a {
  background-color: var(--main-accent);
  border: none;
  font-size: clamp(1.05rem, 0.804rem + 0.789vw, 1.75rem);
  color: #faf0e6;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.action a:hover {
  background-color: #2888c0;
}

.carousel-header {
  --fontSize: clamp(4rem, -0.587rem + 5.489vw, 6rem);
  padding-right: clamp(3.875rem, -1.572rem + 6.518vw, 6.25rem);
  color: var(--main-text-color);
  font-size: var(--fontSize);
  width: clamp(24.188rem, -6.342rem + 36.535vw, 37.5rem);
  align-self: center;
  line-height: calc(var(--fontSize) * 1.6);
}

.carousel-cont {
  position: relative;
  display: flex;
  justify-content: center;
  margin: clamp(4.063rem, 2.951rem + 5.556vw, 6.25rem) 0;
  background: linear-gradient(
    180deg,
    rgba(1, 60, 88, 1) 0%,
    rgba(47, 158, 221, 0.5) 50%,
    rgba(1, 60, 88, 1) 100%
  );
  padding: clamp(1.938rem, -7.952rem + 11.835vw, 6.25rem);
}

.checkpoint {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  bottom: 30%;
  left: 50%;
}

.img-carousel {
  width: clamp(15.625rem, -1.835rem + 87.302vw, 50rem);
}

.img-carousel img {
  border-radius: 25px;
}

.slick-arrow.slick-next {
  inset-inline-end: -25px;
}

.slick-arrow.slick-prev {
  inset-inline-start: -25px;
}

.slick-dots.slick-dots-bottom {
  z-index: 2;
  bottom: -15px;
}

.impact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.impact h2 {
  color: var(--main-text-color);
  font-size: clamp(1.75rem, 1.6rem + 0.75vw, 2.5rem);
}

.stats {
  display: flex;
  justify-content: space-evenly;
  margin-top: clamp(3.75rem, 3.563rem + 0.938vw, 4.688rem);
  margin-bottom: 100px;
  gap: clamp(4.375rem, -3.365rem + 18.429vw, 18.75rem);
}

.stats article {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: clamp(1rem, 0.731rem + 0.641vw, 1.5rem);
  color: var(--main-text-color);
  text-align: center;
}

.stats article strong {
  font-size: clamp(3rem, 2.462rem + 1.282vw, 4rem);
  color: var(--main-accent);
}

.releases {
  display: flex;
  gap: clamp(3.125rem, -1.374rem + 6.353vw, 6.25rem);
  justify-content: center;
  margin-top: 50px;
}

.releases article {
  width: clamp(25rem, 16.002rem + 12.706vw, 31.25rem);
  text-align: center;
  padding: 25px clamp(0.625rem, -0.359rem + 4.92vw, 3.125rem);
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--secondary-background);
  height: fit-content;
}

.releases article h3 {
  font-size: clamp(1.5rem, 1.303rem + 0.984vw, 2rem);
  color: var(--main-text-color);
}

.releases article img {
  width: clamp(25rem, 16.002rem + 12.706vw, 31.25rem);
  margin: 20px 0;
  border-radius: 5px;
}

.releases article p {
  font-size: clamp(1.1rem, 1.041rem + 0.295vw, 1.25rem);
  color: var(--sub-text-color);
}

.releases a {
  height: fit-content;
}

.banner {
  width: clamp(18.75rem, 3.75rem + 75vw, 93.75rem) !important;
  margin: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem) 0;
}

.testimonial {
  background-color: var(--sub-text-color);
  width: clamp(17.5rem, 17.25rem + 1.25vw, 18.75rem);
  padding: clamp(3.438rem, 3.25rem + 0.938vw, 4.375rem)
    clamp(0.625rem, 0.375rem + 1.25vw, 1.875rem) 20px;
  margin: 0 clamp(1.563rem, 1.375rem + 0.938vw, 2.5rem);
  position: relative;
  border: none;
  border-radius: 15px;
}

.testimonial svg {
  width: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem);
  height: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem);
  position: absolute;
  left: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
  top: clamp(0.5rem, 0.475rem + 0.125vw, 0.625rem);
}

.testimonial p {
  font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
  text-align: center;
}

@media screen and (max-width: 424px) {
  .action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .action button {
    width: 150px;
  }
}

@media screen and (max-width: 671px) {
  .stats {
    flex-direction: column;
  }
}

@media screen and (max-width: 1132px) {
  .releases {
    flex-direction: column;
  }

  .releases article img {
    width: clamp(15.625rem, 11.935rem + 18.45vw, 25rem);
  }

  .releases article {
    width: clamp(15.625rem, 11.935rem + 18.45vw, 25rem);
  }

  .rfm-overlay::before,
  .rfm-overlay::after {
    width: 0 !important;
  }
}

@media screen and (max-width: 553px) {
  .backsplash {
    object-fit: cover;
  }
}

@media screen and (max-width: 1337px) {
  .carousel-cont {
    align-items: center;
    flex-direction: column;
  }

  .carousel-header {
    padding-right: 0;
    margin-bottom: clamp(1.563rem, 1.366rem + 0.983vw, 2.188rem);
    width: auto;
    --fontSize: clamp(1.5rem, 0.713rem + 3.933vw, 4rem);
  }

  .checkpoint {
    top: 0;
    left: 50%;
  }
}
