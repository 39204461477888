.heading {
  margin: 50px 0;
  font-size: 2.5rem;
  text-align: center;
  color: var(--main-text-color);
}

.question {
  --font-size: clamp(1.5rem, 1.45rem + 0.25vw, 1.75rem);
  text-align: center;
  color: var(--main-text-highlight);
  line-height: calc(var(--font-size) * 1.6);
  font-size: var(--font-size);
  margin-bottom: 50px;
  padding: 0 10px;
}

.wform {
  display: flex;
  justify-content: center;
}

.background {
  background-color: var(--secondary-background);
  padding: clamp(1.25rem, 0.875rem + 1.875vw, 3.125rem)
    clamp(0.625rem, 0.125rem + 2.5vw, 3.125rem);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  color: var(--main-text-color);
  font-size: 1.5rem;
}

.workshop-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 70px;
  padding: 0 clamp(0.625rem, 0.125rem + 2.5vw, 3.125rem);
}

.feature {
  display: flex;
  gap: 15px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  width: clamp(16.25rem, 14.5rem + 8.75vw, 25rem);
  color: var(--main-black-text);
}

.description h2 {
  transition: all 0.35s linear;
  margin-bottom: 10px;
  font-size: clamp(1.25rem, 1.2rem + 0.25vw, 1.5rem);
}

.feature:hover .description h2 {
  color: var(--main-accent);
}

.feature svg {
  transition: all 0.35s linear;
}

.feature:hover svg {
  fill: var(--main-accent);
  stroke: var(--main-accent);
}

.feature:nth-of-type(1) svg {
  width: 200px;
  height: auto;
}

.feature:nth-of-type(2) svg {
  width: 160px;
  height: auto;
}

.feature:nth-of-type(3) svg {
  width: 80px;
  height: auto;
}
