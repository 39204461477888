@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

* {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-style: normal;
  padding: 0;
  margin: 0;
}

:root {
  --background: #013c58;
  --main-accent: #2f9edd;
  --sub-accent: #013c58;
  --main-text-color: #edeade;
  --sub-text-color: #cbcaca;
  --main-text-highlight: #fdca77;
  --main-black-text: #3b444b;
  --secondary-background: #0c4d6b;
}

html {
  background-color: var(--background);
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar {
  border-radius: 8px;
  background-color: var(--secondary-background);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--main-accent);
}
