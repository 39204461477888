footer {
  background-color: var(--secondary-background);
  display: flex;
  justify-content: space-evenly;
  gap: clamp(3.125rem, 2.05rem + 5.376vw, 4.688rem);
  flex-wrap: wrap;
  margin-top: 75px;
  padding: 20px 0;
  text-align: center;
}

footer section {
  display: flex;
  flex-direction: column;
  color: var(--sub-text-color);
  padding: 0 20px;
}

footer section h1 {
  margin-bottom: 10px;
}

footer section:last-of-type h1 {
  margin-bottom: 20px;
}

footer .head {
  gap: 10px;
}

footer .head img:nth-child(1) {
  width: 115px;
}

footer .head img:nth-child(2) {
  width: 200px;
}

footer .contact {
  gap: 10px;
}

footer .overview {
  gap: 10px;
}

footer .overview a {
  color: var(--sub-text-color);
}

footer .overview a:hover {
  color: #ebebeb;
}

footer .social h1 {
  font-size: 1.5rem;
  margin-top: 15px;
}

footer .social div {
  display: flex;
  gap: 20px;
  align-self: center;
}

footer .social div svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

footer .social a {
  color: var(--sub-text-color);
}

footer .subscribe p {
  max-width: clamp(17.5rem, 16rem + 7.5vw, 25rem);
  text-align: left;
  font-size: clamp(1rem, 0.96rem + 0.2vw, 1.2rem);
}

.subscribe-submit {
  margin-top: 20px;
  display: flex;
}

.subscribe-submit input {
  padding: 10px;
  font-size: clamp(1rem, 0.96rem + 0.2vw, 1.2rem);
  margin-right: 10px;
  border-radius: 10px;
  background-color: #9dc6df;
  border: none;
  flex: 1;
  width: clamp(10rem, 8.725rem + 6.375vw, 16.375rem);
}

.subscribe-submit input:focus {
  outline: none;
}

.subscribe-submit button {
  border-radius: 10px;
  font-size: clamp(1rem, 0.96rem + 0.2vw, 1.2rem);
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: var(--main-accent);
  color: var(--sub-text-color);
}

.subscribe-submit button:hover {
  background-color: #4fafe6;
}

#copyright {
  text-align: center;
  color: var(--sub-text-color);
  padding: 10px 0;
  margin-top: 25px;
}

@media screen and (max-width: 785px) {
  footer .head {
    align-items: center;
  }
}
